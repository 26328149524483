.footer {
    padding-top: 40px;
    width: 100%;
    transition: background-color 0.5s ease, color 0.5s ease;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column; /* Altera a direção do layout para coluna */
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.footer-content {
    display: flex;
    flex-direction: column; /* Altera a direção do layout para coluna */
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 1.2rem;
}

.footer-icons {
    display: flex;
    gap: 30px;
    font-size: 3rem; /* Aumentado */
    margin-bottom: 20px;
    transition: font-size 0.3s ease; /* Adiciona transição suave */
}

.footer-icons i {
    transition: transform 0.3s ease; /* Adiciona transição suave */
}

.footer-icons i:hover {
    transform: scale(1.2); /* Aumenta o tamanho ao passar o rato */
}

.footer-text {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center; /* Centraliza o texto */
}

/* Light Theme */
.footer.light {
    background-color: white;
    color: deepskyblue;
}

.footer.light .text-deepskyblue {
    color: deepskyblue !important;
}

.footer.light .v-btn {
    color: deepskyblue;
}

.footer.light .footer-icons i {
    color: deepskyblue;
}

/* Dark Theme */
.footer.dark {
    background-color: black;
    color: #f0c040;
}

.footer.dark .text-#f0c040 {
    color: #f0c040 !important;
}

.footer.dark .v-btn {
    color: #f0c040;
}

.footer.dark .footer-icons i {
    color: #f0c040;
}

/* Adjust layout for mobile view */
/* Adjust layout for mobile view */
@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer-content {
        flex-direction: column;
        gap: 20px;
        width: 100%; /* Garante que o conteúdo ocupe toda a largura */
    }

    .footer-icons {
        justify-content: center;
        gap: 20px; /* Ajusta o espaço entre ícones para telas menores */
        font-size: 2.5rem; /* Diminui o tamanho dos ícones para telas menores */
    }

    .footer-text {
        font-size: 1rem; /* Diminui o tamanho do texto para melhor leitura em telas menores */
        margin-top: 10px; /* Adiciona espaçamento entre ícones e texto */
    }
}
