.light {
    background-color: white;
    color: black;
}

.light .contact-info h2,
.light .contact-info ul,
.light .contact-info li,
.light .contact-info i,
.light .contact-info a,
.light .contact-info span,
.light .contact-form .form-group{
    color: deepskyblue;
}

.light .contact-form input,
.light .contact-form textarea {
    color: deepskyblue;
}

.light .submit-btn {
    color: white;
    background-color: deepskyblue;
}
.light .portfolio-btn {
    color: white;
    background-color: deepskyblue;
}

.light .download-btn {
    color: white;
    background-color: deepskyblue;
}

.light .submit-btn:hover {
    color: deepskyblue;
    background-color: white;
}
.light .portfolio-btn:hover {
    color: deepskyblue;
    background-color: white;
}
.light .download-btn:hover {
    color: deepskyblue;
    background-color: white;
}

.light .contact-info {
    background-color: black;
}

.light .contact-container {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 1);
}

.light .portfolio-page{
    color: white;
    background-color: deepskyblue;
}

.light .about-page{
    color: white;
    background-color: deepskyblue;
}

.light .mobile-menu-toggle{
    color: deepskyblue;
    background-color: white;
}
.dark .mobile-menu-toggle{
    color: #f0c040;
    background-color: black;
}

.dark {
    background-color: black;
    color: white;
}

.dark .contact-info h2,
.dark .contact-info ul,
.dark .contact-info li,
.dark .contact-info i,
.dark .contact-info a,
.dark .contact-info span,
.dark .contact-form .form-group {
    color: #f0c040;
}


.dark .contact-form input,
.dark .contact-form textarea {
    color: #f0c040;
}

.dark .submit-btn {
    color: black;
    background-color: #f0c040;
}

.dark .portfolio-btn {
    color: black;
    background-color: #f0c040;
}

.dark .download-btn {
    color: black;
    background-color: #f0c040;
}

.dark .submit-btn:hover {
    color: #f0c040;
    background-color: black;

}
.dark .portfolio-btn:hover {
    color: #f0c040;
    background-color: black;

}

.dark .download-btn:hover {
    color: #f0c040;
    background-color: black;

}

.dark .portfolio-page{
    background-color: #f0c040; /* Fundo de destaque semelhante ao Landing */
    color: black; /* Cor do texto para manter o contraste */
}

.dark .about-page{
    background-color: #f0c040; /* Fundo de destaque semelhante ao Landing */
    color: black; /* Cor do texto para manter o contraste */
}

.dark .contact-info {
    background-color: white;
}

.dark .contact-container {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 1);
}


/* Estilos globais para transição */
* {
    transition: background-color 0.5s ease, color 0.5s ease;
}