/* Hero Section */
html {
    scroll-behavior: smooth; /* Adicionado para scroll suave */
}

.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 100vh;
}

.hero-content {
    text-align: center;
}

.hero-content h1 {
    font-size: 3rem; /* Ajustado para tamanhos menores */
    margin: 0;
    text-align: center;
}

.hero-content h2 {
    font-size: 2rem; /* Ajustado para tamanhos menores */
    margin: 10px 0;
    color: #f0c040;
}

.hero-content p {
    font-size: 1.5rem; /* Ajustado para tamanhos menores */
    margin-bottom: 30px;
}

.hero-content .btn {
    padding: 10px 20px;
    background-color: transparent;
    border: 2px solid #f0c040;
    color: #f0c040;
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.5s ease;
}

.hero-content .btn:hover {
    background-color: #f0c040;
    color: #000;
}


/* Arrow Down */
.arrow-down {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2rem;
    transition: color 0.5s ease;
}


.about {
    padding: 50px 20px; /* Reduzido para melhor ajuste em telas menores */
    text-align: center;
    transition: background-color 0.5s ease, color 0.5s ease;
    height: 100vh;
}

.about-content {
    max-width: 800px; /* Alargado para melhorar a leitura */
    margin: 0 auto;
    font-size: 1.2rem; /* Aumentado o tamanho do texto */
    line-height: 2rem;
    text-align: justify;
}

.about-content h2 {
    text-align: center;
    font-size: 3rem; /* Ajustado para tamanhos menores */
}

.about-content p {
    font-size: 1.5rem; /* Ajustado para tamanhos menores */
    line-height: 2rem;
    padding-bottom: 2rem;
}

/* Logos Section */
.logos {
    display: flex;
    justify-content: center;
}

.logo {
    height: 80px; /* Reduzido para melhor ajuste em telas menores */
    width: auto;
    object-fit: contain;
}

/* Discover Section */
.discover {
    padding: 30px 20px; /* Reduzido para melhor ajuste em telas menores */
    margin-bottom: 5rem;
    text-align: center;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.discover-btn {
    padding: 10px 20px; /* Ajustado para tamanhos menores */
    background-color: #f0c040;
    color: #000;
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.5s ease;

}

.discover-btn:hover {
    background-color: #000;
    color: #f0c040;
}

/* Separador */
.separator {
    width: 100%;
    height: 2px;
    background-color: #f0c040;
    margin: 40px 0; /* Espaçamento acima e abaixo do separador */
}


/* Hero Section */
@media (max-width: 768px) {
    .hero {
        flex-direction: column; /* Imagem em cima e texto embaixo */
        padding: 10px; /* Reduzir padding para telas menores */
    }

    .hero-content h1 {
        font-size: 2rem; /* Diminui o tamanho do título */
    }

    .hero-content h2 {
        font-size: 1.5rem; /* Diminui o tamanho do subtítulo */
    }

    .hero-content p {
        font-size: 1rem; /* Diminui o tamanho do parágrafo */
        margin-bottom: 20px; /* Reduzir o espaço inferior */
    }

    .hero-content .btn {
        padding: 8px 16px; /* Reduz o tamanho do botão */
        font-size: 0.875rem; /* Ajusta o tamanho da fonte do botão */
    }

    .arrow-down {
        font-size: 1.5rem; /* Diminui o tamanho da seta */
    }


/* About Section */

    .about {
        padding: 20px 10px; /* Reduz o padding */
        height: auto; /* Permite que a altura seja determinada pelo conteúdo */
    }

    .about-content {
        font-size: 1rem; /* Ajusta o tamanho do texto */
        line-height: 1.5rem; /* Ajusta o espaçamento entre linhas */
    }

    .about-content h2 {
        font-size: 2rem; /* Diminui o tamanho do título */
    }

    .about-content p {
        font-size: 1.25rem; /* Diminui o tamanho dos parágrafos */
        line-height: 1.75rem; /* Ajusta o espaçamento entre linhas */
    }


/* Logos Section */

    .logos {
        flex-direction: column; /* Alinha os logos verticalmente */
        gap: 10px; /* Espaçamento entre os logos */
    }

    .logo {
        height: 60px; /* Diminui o tamanho dos logos */
    }


/* Discover Section */

    .discover {
        padding: 20px 10px; /* Reduz o padding */
        margin-bottom: 2rem; /* Diminui o espaço inferior */
    }

    .discover-btn {
        padding: 8px 16px; /* Reduz o tamanho do botão */
        font-size: 0.875rem; /* Ajusta o tamanho da fonte do botão */
    }
}



