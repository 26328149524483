.portfolio-page {
    font-family: 'Poppins', sans-serif;
    transition: background-color 0.5s ease, color 0.5s ease; /* Transições suaves */
}


.portfolio-section {
    transition: background-color 0.5s ease, color 0.5s ease;
}

.landing-portfolio-section {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-image: url('../../assets/coding.png');
    background-size: cover;
    background-position: center;
    color: white;
}

.landing-portfolio-section .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.landing-portfolio-section h1 {
    font-size: 4rem;
    margin-bottom: 20px;
}

.landing-portfolio-section p {
    font-size: 1.5rem;
    margin-bottom: 30px;
}

.landing-portfolio-section h1,
.landing-portfolio-section p,
.landing-portfolio-section .portfolio-btn {
    position: relative;
    z-index: 2;
}

.landing-portfolio-section .portfolio-btn {
    padding: 10px 20px; /* Ajustado para tamanhos menores */
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.5s ease;
}

.landing-portfolio-section .portfolio-btn:hover {
}

.portfolio-section {
    display: flex;
    align-items: center;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
    margin: 50px 0;
    padding: 20px;
    background: #fff; /* Fundo claro */
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: #000; /* Texto escuro */
}

.portfolio-section.visible {
    opacity: 1;
    transform: translateY(0);
}

.portfolio-section-image {
    width: 40%;
    height: auto;
    border-radius: 10px;
    margin-right: 20px;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: 2px solid #ddd;
}

.portfolio-section-content {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.portfolio-section h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
}

.portfolio-section h3 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

.portfolio-section a {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #f0c040; /* Consistente com o botão no Landing */
    text-decoration: none;
}

.portfolio-section a:hover {
    color: #000; /* Inverter cores no hover */
}

.portfolio-section p, .portfolio-section ul {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #555;
}

.portfolio-section:nth-child(odd) {
    flex-direction: row-reverse;
}
/* Ajustes específicos para telas menores que 400px */

@media (max-width: 768px) {

    .portfolio-section {
        flex-direction: column;
        text-align: center;
        padding: 15px;
        margin: 20px 0;
    }

    .portfolio-section-image {
        width: 100%;
        margin-bottom: 15px;
    }

    .portfolio-section-content {
        width: 100%;
        padding: 0;
        text-align: left; /* Ajustar para melhor leitura em dispositivos móveis */
    }

    .portfolio-section h2 {
        font-size: 2rem;
    }

    .portfolio-section p, .portfolio-section ul {
        font-size: 1rem;
        line-height: 1.5rem;
        margin-bottom: 10px;
    }
}

@media (max-width: 500px) {
    .portfolio-section:nth-child(odd) {
        flex-direction: column-reverse;
    }
    .portfolio-section {
        padding: 10px;
        margin: 10px 0;
        box-shadow: none;
        flex-direction: column; /* Garantir que as imagens apareçam acima do texto */
    }

    .portfolio-section-image {
        width: 100%;
        height: auto;
        margin-bottom: 15px;
        order: -1; /* Mover a imagem para o topo da secção */
    }

    .portfolio-section-content {
        width: 100%;
        padding: 0;
        text-align: justify;
    }

    .portfolio-section h2 {
        font-size: 1.5rem;
    }

    .portfolio-section p, .portfolio-section ul {
        font-size: 0.875rem;
        line-height: 1.4rem;
    }

    .portfolio-section ul {
        padding-left: 20px;
    }
}

